/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/unbound-method */
import { computed, useContext } from '@nuxtjs/composition-api';
import { CartItem, useCart, useUser } from '@gemini-vsf/composables';
import { KlaviyoAddToCartEventData, KlaviyoCheckoutEventData, KlaviyoProduct, ExtendedProduct as Product } from '~/types/klaviyo';
import { getIdFromGrn } from '~/helpers/stringHelpers';

const useKlaviyo = () => {
  const klaviyo = computed(() => typeof window !== 'undefined' && window._learnq);
  const { cart, load: loadCart } = useCart();
  const { user, load: loadUser } = useUser();
  const {
    $vsf: {
      $gemini: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config: { geminiBaseUrl },
      },
    },
    route: {
      value: { path },
    },
    app,
    app: {
      i18n: {
        localeProperties: { defaultCurrency: currentCurrency },
      },
    },
  } = useContext();

  const cartIsValidForKlaviyo = async (): Promise<boolean> => {
    if (!cart.value) {
      await loadUser();
      await loadCart({
        customQuery: {
          customerCart: 'customerCartCustom',
        },
      });
    }
    return !!(cart.value && cart.value.email);
  };

  const isIdentified = (): boolean => !!klaviyo?.value?.isIdentified();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const currentLocale = computed<string>(() => (path?.split('/')?.[1] === 'it' ? 'it' : 'en'));

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const identifiedEmail = computed<string | undefined>(() => (cart.value && cart.value.email) || (user.value && user.value.email));

  const cartItemToKlaviyoProduct = (item: CartItem): KlaviyoProduct => {
    return {
      ProductID: item.product.sku,
      SKU: item.product.merchant_sku,
      ProductName: item.product.name,
      Quantity: item.quantity,
      ItemPrice: item.prices.price.value,
      RowTotal: item.prices.row_total.value,
      ProductURL: `${geminiBaseUrl}${app.$fixUrlForCurrentLocale(app.$productUrl(item.product))}`,
      ImageURL: item.product.thumbnail.url,
      ProductCategories: item.product.categories.map((category) => category.name),
      Currency: currentCurrency,
    };
  };

  const productToKlaviyoProduct = (product: Product): KlaviyoProduct => {
    return {
      ProductID: product.sku,
      SKU: product.merchant_sku,
      ProductName: product.name,
      Quantity: 1,
      ItemPrice: product.price_range.minimum_price.final_price.value,
      RowTotal: product.price_range.minimum_price.final_price.value,
      ProductURL: `${geminiBaseUrl}${app.$fixUrlForCurrentLocale(app.$productUrl(product))}`,
      ImageURL: product.image.url,
      ProductCategories: product.categories.map((category) => category.name),
      ProductBrand: product.brand_attribute.label,
      ProductGender: product.gender_attribute.label,
      ProductSeason: product.season_code_attribute.label,
      ProductColor: product.color_attribute.label,
    };
  };

  const cartItemsToKlaviyoCheckoutEventProducts = (): KlaviyoProduct[] => {
    return cart.value.items.map((item) => cartItemToKlaviyoProduct(item));
  };

  const generateCheckoutEventId = () => {
    const date = new Date();
    const timestamp = date.getTime();
    const cartId: string = getIdFromGrn(cart.value.id);
    return `${cartId}_${timestamp}`;
  };

  const identifyKlaviyo = (email?: string) => {
    if (!identifiedEmail.value && !email) return;
    try {
      klaviyo.value.push(['identify', { $email: email || identifiedEmail.value }]);
    } catch (error) {
      console.log(error);
    }
  };

  const pushToKlaviyo = async (eventName: string, eventData: object): Promise<boolean> => {
    if (!klaviyo.value) {
      console.log('Klaviyo not initialized');
      return false;
    }
    if (!isIdentified()) {
      try {
        await loadUser();
        await loadCart({
          customQuery: {
            customerCart: 'customerCartCustom',
          },
        });
        identifyKlaviyo();
      } catch (error) {
        console.log(error);
      }
    }
    if (!isIdentified()) return false;
    try {
      const r = klaviyo.value.push(['track', eventName, eventData]);
      return !!r;
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  const klaviyoCheckoutPush = async (eventName?: string): Promise<void> => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const eventId = generateCheckoutEventId();
    try {
      await pushToKlaviyo(eventName || 'Started Checkout', {
        $event_id: eventId,
        $value: cart.value.prices.grand_total.value,
        ItemNames: cart.value.items.map((item: CartItem) => item.product.name),
        ItemImages: cart.value.items.map((item: CartItem) => item.product.thumbnail.url),
        ItemUrls: cart.value.items.map((item: CartItem) => `${geminiBaseUrl}${app.$fixUrlForCurrentLocale(app.$productUrl(item.product))}`),
        ItemsFinalPrices: cart.value.items.map((item: CartItem) => item.prices.price.value),
        // @ts-ignore
        ItemsBrands: cart.value.items.map((item: CartItem) => item?.product?.brand_attribute?.label),
        CheckoutURL: `${geminiBaseUrl}${app.$fixUrlForCurrentLocale('/checkout')}`,
        Categories: [...new Set(cart.value.items.flatMap((item: CartItem) => item.product.categories.map((category) => category.name)))],
        Locale: currentLocale.value,
        Currency: currentCurrency,
        Items: cartItemsToKlaviyoCheckoutEventProducts(),
      } as KlaviyoCheckoutEventData);
    } catch (error) {
      console.log(error);
    }
  };

  const klaviyoAddToCartPush = async (product: Product, quantity: number) => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const klaviyoProduct = productToKlaviyoProduct(product);
    try {
      await pushToKlaviyo('Added to Cart', {
        AddedItemValue: klaviyoProduct.ItemPrice * quantity,
        AddedItemProductName: klaviyoProduct.ProductName,
        AddedItemProductID: klaviyoProduct.ProductID,
        AddedItemSKU: klaviyoProduct.SKU,
        AddedItemCategories: klaviyoProduct.ProductCategories,
        AddedItemImageURL: klaviyoProduct.ImageURL,
        AddedItemURL: klaviyoProduct.ProductURL,
        AddedItemPrice: klaviyoProduct.ItemPrice,
        AddedItemQuantity: quantity,
        AddedItemBrand: product.brand_attribute.label,
        AddedItemGender: product.gender_attribute.label,
        AddedItemSeason: product.season_code_attribute.label,
        AddedItemColor: product.color_attribute.label,
        CheckoutURL: `${geminiBaseUrl}${app.$fixUrlForCurrentLocale('/checkout')}`,
        Locale: currentLocale.value,
        Currency: currentCurrency,
      } as KlaviyoAddToCartEventData);
    } catch (error) {
      console.log(error);
    }
  };

  const klaviyoApplyCouponPush = async (couponCode: string) => {
    const canPush = await cartIsValidForKlaviyo();
    if (!canPush) return;
    const eventId = generateCheckoutEventId();
    try {
      await pushToKlaviyo('Added Coupon', {
        $event_id: eventId,
        CouponCode: couponCode,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const klaviyoPagePush = async (pageName: string) => {
    try {
      await pushToKlaviyo('Viewed Page', {
        PageName: pageName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const klaviyoViewedProductPush = async (product: Product) => {
    try {
      await pushToKlaviyo('Viewed Product', {
        ProductName: product.name,
        ProductID: product.sku,
        SKU: product.merchant_sku,
        ProductBrand: product.brand_attribute.label,
        ProductGender: product.gender_attribute.label,
        ProductSeason: product.season_code_attribute.label,
        ProductColor: product.color_attribute.label,
        ProductImageURL: product.image.url,
        ProductCategories: product.categories.map((category) => category.name),
        ProductFinalPrice: product.price_range.minimum_price.final_price.value,
        ProductRegularPrice: product.price_range.minimum_price.regular_price.value,
        ProductURL: `${geminiBaseUrl}${app.$fixUrlForCurrentLocale(app.$productUrl(product))}`,
        Locale: currentLocale.value,
        Currency: currentCurrency,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    klaviyoCheckoutPush,
    klaviyoAddToCartPush,
    klaviyoApplyCouponPush,
    identifyKlaviyo,
    klaviyoPagePush,
    klaviyoViewedProductPush,
  };
};

export default useKlaviyo;
