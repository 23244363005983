// @ts-ignore
import { useContext, ref } from '@nuxtjs/composition-api';
import { useCart, useWishlist as useWishlistComposable, Product } from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import { WishlistItem } from '@gemini-vsf/api-client';
import { getProductDetailsCommand } from '~/composables/useWishlist/getProductDetailCommand';
import { useAddToCart } from '~/helpers/cart/addToCart';

const useWishlist = (productList) => {
  const { addItem: addProductToCart, error: cartError } = useCart();
  const { showCartError } = useAddToCart();
  const { wishlist, load: loadWishlist, removeItem, addItem, hasItemsInWishlist, updateItem } = useWishlistComposable();
  const context = useContext();

  const itemsInWishlist = ref<Record<string, boolean>>({});

  const filterProductSkus = (products?) => {
    const productsToFilter = products || productList.value;
    return productsToFilter
      .filter((product) => !product?.isMoreItem)
      .reduce((acc, product) => {
        acc.push(product.sku);

        return acc;
      }, []);
  };

  const pushItems = (items) => {
    itemsInWishlist.value = { ...itemsInWishlist.value, ...items };
  };

  const loadItemsFromList = async (initialProductList) => {
    // todo check if we can use some sort of cache here
    pushItems(await hasItemsInWishlist({ items: filterProductSkus(initialProductList) }));
  };

  const toggleWishlist = async (product: Product, initialProductList) => {
    const configurableProduct = { ...product, configurable_product_options_selection: null };
    if (Object.keys(itemsInWishlist.value).length === 0 && initialProductList) {
      await loadItemsFromList(initialProductList);
    }
    await (!itemsInWishlist.value[configurableProduct.sku]
      ? addItem({ product: configurableProduct, quantity: 1 })
      : removeItem({ product: configurableProduct }));
    pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
  };

  const addItemToCart = async (wishlistItem: WishlistItem) => {
    const productGrn = wishlistItem.item_grn;
    const productId = wishlistItem.product?.uid.split('::')?.[1];
    const variant = wishlistItem.product.variants.find((item) => item.product.uid === productGrn);
    const product = await getProductDetailsCommand.execute(context, {
      filter: {
        // @ts-ignore
        uid: productId,
      },
      // @ts-ignore
      configurations: variant.option_uids as string[],
    });
    // @ts-ignore
    await addProductToCart({ product, quantity: wishlistItem.qty });
    if (cartError.value.addItem) {
      showCartError(cartError.value.addItem);
      return;
    }
    // @ts-ignore
    await removeItem({ product: wishlistItem.product });
  };

  return {
    itemsInWishlist,
    loadItemsFromList,
    toggleWishlist,
    removeItemFromWishlist: removeItem,
    addItemToWishlist: addItem,
    addItemToCart,
    wishlist,
    loadWishlist,
    updateItem,
  };
};

export default useWishlist;
