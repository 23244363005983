/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { contentGetters, useContent } from '@gemini-vsf/composables';

const useConfig = (key = 'site-config-data') => {
  const siteConfigData = sharedRef({}, key);
  const { blocks, loadBlocks } = useContent();

  const loadConfig = async () => {
    try {
      const identifier = 'config';
      await loadBlocks({
        identifiers: [identifier],
      });
      const blockData = (await contentGetters.getCmsBlockContent(blocks.value)) || {};
      siteConfigData.value = blockData?.[identifier];
    } catch (error) {
      console.error(error);
      console.warn('Could not get Configs block content');
    }
  };

  return {
    loadConfig,
    siteConfigData,
  };
};

export default useConfig;
