import { ref, useContext } from '@nuxtjs/composition-api';

type RecaptchaConfigType = {
  isEnabled: boolean;
};
export const useRecaptcha = () => {
  const { $recaptcha, $config } = useContext();
  return {
    isEnabled: ref((typeof $recaptcha !== 'undefined' && !!($config?.recaptcha as RecaptchaConfigType)?.isEnabled) || false),
    $recaptcha,
  };
};
