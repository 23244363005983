/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, computed, useRoute, ref } from '@nuxtjs/composition-api';
import { useCart, cartGetters, productGetters, useUser, userGetters } from '@gemini-vsf/composables';
import { getIdFromGrn } from '~/helpers/stringHelpers';
import { jsHelpers } from '~/helpers/jsHelpers';

interface GtmPagePush {
  event: string;
  content_name: string;
  content_url: string;
  item_id?: string | number;
}

const useGtm = () => {
  const {
    app: {
      $gtm: googleTagManager,
      i18n: { locales },
      $cookies,
    },
    $vsf,
  } = useContext();
  // @ts-ignore
  const currency = $cookies.get('vsf-currency');
  const fullPath = useRoute();
  const currentLocaleCode = computed(() => fullPath.value.fullPath.split('/')[1]);
  // @ts-ignore
  const currentCurrency = computed(() => locales.find((l) => l.code === currentLocaleCode.value)?.defaultCurrency);
  const isClientAndHasCurrency = computed(() => !!process.client && currentCurrency.value);
  const { cart } = useCart();
  const { isAuthenticated, user } = useUser();
  const { arrayIsValidAndHasItems, vueArrayToNormalArray } = jsHelpers();

  const checkoutGtmPush = (step: number, description: string) => {
    try {
      if (isClientAndHasCurrency.value) {
        googleTagManager.push({
          event: 'checkout',
          ecommerce: {
            currencyCode: currentCurrency.value,
            checkout: {
              actionField: {
                step,
                description,
                action: 'checkout',
              },
            },
          },
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const cartGtmPush = () => {
    try {
      const productsInCart =
        cartGetters
          .getItems(cart.value)
          .filter((p) => p.product && p.quantity && p.prices)
          .map((i) => ({
            item_id: productGetters.getProductMerchantSku(i.product).split('_')[0],
            item_name: productGetters.getName(i.product),
            price: i.prices.row_total.value ?? i.prices.row_regular_total.value,
            quantity: i.quantity,
            item_variant: productGetters.getProductMerchantSku(i.product),
            category: productGetters.getCategoryNameProductPage(
              i.product,
              // @ts-ignore
              Array.isArray(i.product.categories) ? [...i.product.categories].pop() : []
            ),
          })) ?? [];
      const criteoProductsArray =
        cartGetters
          .getItems(cart.value)
          .filter((p) => p.product && p.quantity && p.prices)
          .map((i) => ({
            id: productGetters.getProductMerchantSku(i.product).split('_')[0],
            price: i.prices.row_total.value ?? i.prices.row_regular_total.value,
            quantity: i.quantity,
          })) ?? [];
      // @ts-ignore
      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
        googleTagManager.push({
          criteoProductsArray,
          transaction_id: cart.value.id,
          value: cart.value.prices.grand_total.value,
          affiliation: 'biffi.com',
          PageType: 'cart-page',
          ecommerce: {
            items: { ...productsInCart },
            currencyCode: cart.value.prices.grand_total.currency,
          },
          event: 'view_cart',
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const applyCouponGtmPush = (couponCode: string, isCouponSuccessfullyApplied: boolean) => {
    try {
      const productsInCart =
        cartGetters
          .getItems(cart.value)
          .filter((p) => p.product && p.quantity && p.prices)
          .map((i) => ({
            item_id: productGetters.getProductMerchantSku(i.product).split('_')[0],
          })) ?? [];
      // @ts-ignore
      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
        googleTagManager.push({
          event: 'apply_coupon',
          coupon_code: couponCode,
          applied: isCouponSuccessfullyApplied,
          items: {
            ...productsInCart,
          },
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const categoryGtmPush = (products, categoryName, categoryId) => {
    try {
      const productsList = products.filter(Boolean).map((p, i) => ({
        item_id: productGetters.getProductMerchantSku(p).split('_')[0],
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        item_name: productGetters.getName(p),
        // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary, @typescript-eslint/no-unsafe-argument
        price: productGetters.getPrice(p)?.special ? productGetters.getPrice(p).special : productGetters.getPrice(p).regular,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        item_category: categoryName,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        position: i + 1,
      }));

      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsList)) {
        googleTagManager.push({
          ecommerce: {
            items: {
              ...productsList,
            },
          },
          PageType: 'product-list',
          event: 'view_item_list',
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const customerGtmPush = () => {
    try {
      if (isClientAndHasCurrency.value) {
        if (isAuthenticated.value) {
          googleTagManager.push({
            customerLoggedIn: 1,
            // @ts-ignore
            firstname: userGetters.getFirstName(user.value),
            // @ts-ignore
            lastname: userGetters.getLastName(user.value),
            // @ts-ignore
            email: userGetters.getEmailAddress(user.value),
          });
          return;
        }
        googleTagManager.push({
          customerLoggedIn: 0,
          customerId: 0,
          customerGroupId: '1',
          customerGroupCode: 'GENERAL',
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const pageGtmPush = (pageTitle: string, pageType = '', productMerchantSku = null) => {
    try {
      // PUSH CONSENTS IN FIRST PAGE FOR DEFAULT OPTIONS
      googleTagManager.push([
        'consent',
        'default',
        {
          ad_personalization: 'denied',
          ad_storage: 'denied',
          ad_user_data: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'granted',
          wait_for_update: 500,
        },
      ]);
      if (isClientAndHasCurrency.value && fullPath.value.fullPath && pageTitle) {
        const pagePushContent: GtmPagePush = {
          event: 'PageView',
          content_name: pageTitle,
          content_url: `https://www.biffi.com${fullPath.value.fullPath}`,
          ...(pageType ? { page_type: pageType } : {}),
        };
        if (productMerchantSku) pagePushContent.item_id = productMerchantSku;
        googleTagManager.push(pagePushContent);
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const productGtmPush = (product, brand) => {
    try {
      if (isClientAndHasCurrency.value && product) {
        const productDataGtm = {
          item_id: productGetters.getProductMerchantSku(product).split('_')[0],
          item_name: productGetters.getName(product),
          price: productGetters.getFinalPrice(product),
          category: productGetters.getCategoryNameProductPage(product, Array.isArray(product.categories) ? [...product.categories].pop() : []),
          // @ts-ignore
          list_sku_variants: vueArrayToNormalArray(productGetters.getListVariantSku(product)),
          item_brand: brand,
        };

        googleTagManager.push({
          ecommerce: {
            currency,
            items: [
              {
                ...productDataGtm,
                categoryId: product.categories.find((c) => c.name === productDataGtm.category).uid,
              },
            ],
            PageType: 'product-page',
          },
          event: 'view_item',
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  const purchaseGtmPush = (order) => {
    try {
      if (isClientAndHasCurrency.value && order && order.transaction_id) {
        const criteoProductsArray =
          order.items.map((i) => ({
            id: i.item_id,
            price: Number(i.price),
            quantity: i.quantity,
          })) ?? [];
        googleTagManager.push({
          event: 'purchase',
          criteoProductsArray,
          ecommerce: {
            ...order,
          },
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
    // ----- stylight tracking -----
    try {
      if (isClientAndHasCurrency.value && order && order.transaction_id) {
        // @ts-ignore
        const { stylightTest, stylightPropertyIds } = $vsf.$gemini.config;
        let stylightCountry = localStorage.getItem('stylight-country');
        if (!stylightCountry) {
          const selectedCountry = JSON.parse(localStorage.getItem('vsf-country-selected'));
          stylightCountry = selectedCountry?.countryIso?.toLowerCase();
        }
        const stylightPropertyId = stylightCountry && stylightPropertyIds ? stylightPropertyIds[stylightCountry] : '';
        if (stylightPropertyId) {
          googleTagManager.push({
            event: 'stylight-sales-confirmation',
            stylight: {
              test: stylightTest,
              propertyId: stylightPropertyId,
              orderId: order.transaction_id,
              currency: order.currencyCode,
              totalAmount: Number.parseFloat(order.value),
              itemCount: order.items.length,
            },
          });
        }
        if (localStorage.getItem('stylight-country')) {
          localStorage.removeItem('stylight-country');
        }
      }
    } catch {
      console.warn('Could not send GTM event for Stylight.');
    }
    // ----- end stylight tracking -----
  };

  // passing productPrice to use method in add and remove from cart
  const actionCartGtmPush = (product, quantity: number, event: string, gtmIndex: number, productPrice = 0, addFromCard = false) => {
    try {
      if (isClientAndHasCurrency.value && product && quantity) {
        googleTagManager.push({
          event: `${event}`,
          ecommerce: {
            items: [
              product?.vsf_typename === 'ConfigurableProduct'
                ? {
                    item_id: productGetters.getProductMerchantSku(product) || '',
                    item_name: product.name || '',
                    price: productPrice !== 0 ? productPrice : productGetters.getFinalPrice(product),
                    item_brand: product.brand_attribute?.label || '',
                    index: gtmIndex,
                    affiliation: addFromCard ? 'Card ruler' : 'PDP',
                    quantity,
                  }
                : {
                    item_id: productGetters.getProductMerchantSku(product).split('_')[0] || '',
                    item_name: product.name || '',
                    item_brand: product.brand_attribute?.label || '',
                    price: productPrice !== 0 ? productPrice : productGetters.getFinalPrice(product),
                    index: gtmIndex,
                    item_variant: productGetters.getProductMerchantSku(product),
                    affiliation: addFromCard ? 'Card ruler' : 'PDP',
                    quantity,
                  },
            ],
            currencyCode: currentCurrency.value,
          },
        });
      }
    } catch {
      console.warn('Could not send GTM event.');
    }
  };

  return {
    checkoutGtmPush,
    cartGtmPush,
    categoryGtmPush,
    customerGtmPush,
    applyCouponGtmPush,
    pageGtmPush,
    productGtmPush,
    purchaseGtmPush,
    actionCartGtmPush,
  };
};

export default useGtm;
