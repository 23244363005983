/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Product } from '@gemini-vsf/api-client';
import { useContext, computed, ComputedRef } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

interface PromoConditions {
  mode?: string;
  eq?: any;
  not?: any;
  include?: any;
  exclude?: any;
}

interface Promo {
  label?: string;
  startAt?: string;
  endAt?: string;
  currencies?: Array<string>;
  markets?: Array<string>;
  conditions?: PromoConditions;
}

interface StarConditions {
  active?: boolean;
  seasons?: string;
  promoRules?: string;
  pdpMessage?: {
    it?: string;
    en?: string;
  };
  pdpExtraLabel?: {
    it?: string;
    en?: string;
  };
}

interface PromoLabel {
  pdpMessage?: string;
  pdpExtraLabel?: string;
}

const conditionsMapProduction = [
  ['AtelierId', 'atelier_id'],
  ['Barcode', 'barcode'],
  ['BarcodeFeed', 'barcode_feed'],
  ['Brand', 'brand'],
  ['Code', 'merchant_sku'],
  ['Color', 'color'],
  ['CostPrice', 'cost_price'],
  ['Gender', 'gender'],
  ['Group', 'group'],
  ['HsCode', 'hs_code'],
  ['Model', 'model'],
  ['Partition', 'partition'],
  ['PeriodoCambio', 'periodo_cambio'],
  ['Related', 'related'],
  ['SeasonCode', 'season_code'],
  ['SeasonType', 'season_type'],
  ['Size', 'size'],
  ['SizeAndFit', 'size_and_fit'],
  ['Sku', 'sku'],
  ['Slot', 'slot'],
  ['SuperColor', 'super_color'],
  ['SuperGroup', 'super_group'],
  ['Upsell', 'upsell'],
  ['Variant', 'variant'],
];

const conditionsMapStaging = [
  ['AtelierId', 'atelier_id'],
  ['Brand', 'brand'],
  ['SuperGroup', 'super_group'],
  ['SeasonCode', 'season_code'],
  ['Gender', 'gender'],
  ['Color', 'color'],
  ['SeasonType', 'season_type'],
  ['SuperColor', 'super_color'],
  ['Group', 'group'],
  ['HsCode', 'hs_code'],
  ['PeriodoCambio', 'periodo_cambio'],
  ['SizeAndFit', 'size_and_fit'],
  ['Upsell', 'upsell'],
  ['Barcodeean', 'barcodeean'],
  ['Code', 'merchant_sku'],
  ['Model', 'model'],
  ['Partition', 'partition'],
  ['Barcode', 'barcode'],
  ['CostPrice', 'cost_price'],
  ['Related', 'related'],
  ['Sku', 'sku'],
  ['Slot', 'slot'],
  ['Variant', 'variant'],
];

const validateTimeInterval = (startTime: string, endTime: string) => {
  const startingTime = Date.parse(startTime) || 1;
  const endingTime = Date.parse(endTime) || 99_999_999_999_999;
  return Date.now() > startingTime && Date.now() < endingTime;
};

const arrInArr = (arr1: Array<string>, arr2: Array<string>) => arr1?.some((element) => arr2?.includes(element));

const checkConditionData = (promoCondition: Array<string> | string, value: string, andMode: boolean, positiveCondition: boolean): boolean =>
  !value || promoCondition?.includes(value) === undefined ? andMode : !!promoCondition.includes(value) === positiveCondition;

const checkCondition = (promoConditions: PromoConditions, code: string, value: string, andMode: boolean): boolean =>
  !!(
    checkConditionData(promoConditions.not?.[code], value, andMode, false) &&
    checkConditionData(promoConditions.exclude?.[code], value, andMode, false) &&
    checkConditionData(promoConditions.eq?.[code], value, andMode, true) &&
    checkConditionData(promoConditions.include?.[code], value, andMode, true)
  );

const checkCollectionCondition = (promoConditions: PromoConditions, product: Product, andMode: boolean) =>
  (promoConditions.exclude?.collection === undefined
    ? andMode
    : !arrInArr(promoConditions.exclude.collection ?? [], product?.categories?.map((c) => c.uid) ?? [])) &&
  (promoConditions.include?.collection === undefined
    ? andMode
    : arrInArr(promoConditions.include?.collection ?? [], product?.categories?.map((c) => c.uid) ?? []));

const useProductPromotions = () => {
  const {
    app: {
      i18n: { localeProperties },
    },
    $vsf: {
      $gemini: { config },
    },
  } = useContext();
  const { siteConfigData } = useConfig();

  const selectedMarket: ComputedRef<string> = computed(() => `${config?.['localesToMarkets']?.[localeProperties.code]?.split('|')?.[0]}`);
  const selectedCurrency: ComputedRef<string> = computed(() => `${localeProperties?.defaultCurrency || ''}`);
  const starConditions: ComputedRef<StarConditions> = computed((): StarConditions => siteConfigData.value?.starConditions || {});
  const promoCanBeChecked: ComputedRef<boolean> = computed(() => selectedMarket.value && selectedCurrency.value && starConditions.value?.active);

  const productHasSeason = (product: Product, seasons: string) =>
    product?.season_code_attribute?.label &&
    seasons
      ?.split(',')
      ?.map((s) => s?.trim())
      ?.includes(product.season_code_attribute.label);

  const productHasPromoRules = (promo: Promo) => {
    const isMarketValidForPromo = selectedMarket.value && (promo.markets?.length === 0 || promo.markets.includes(selectedMarket.value));
    const isCurrencyValidForPromo = selectedCurrency.value && (promo.currencies?.length === 0 || promo.currencies.includes(selectedCurrency.value));
    const isTimeValid = promo.startAt || promo.endAt ? validateTimeInterval(promo.startAt, promo.endAt) : true;
    return isMarketValidForPromo && isCurrencyValidForPromo && isTimeValid;
  };

  const productHasPromoConditions = (product: Product, promoConditions: PromoConditions) => {
    if (promoConditions.mode === 'all') return true;

    const hasConditions =
      ['and', 'or'].includes(promoConditions.mode) &&
      ((promoConditions.eq && Object.keys(promoConditions.eq)?.length > 0) ||
        (promoConditions.exclude && Object.keys(promoConditions.exclude)?.length > 0) ||
        (promoConditions.include && Object.keys(promoConditions.include)?.length > 0) ||
        (promoConditions.not && Object.keys(promoConditions.not)?.length > 0));
    if (!hasConditions) return false;

    const conditionsMap = config['isProductionApi'] ? conditionsMapProduction : conditionsMapStaging;

    const checkedConditions = [
      conditionsMap.map(([promoKey, productKey]) =>
        checkCondition(promoConditions, promoKey, product[productKey] || product[`${productKey}_attribute`]?.value, promoConditions.mode === 'and')
      ),
      checkCollectionCondition(promoConditions, product, promoConditions.mode === 'and'),
    ].flat();

    return promoConditions.mode === 'and' ? !checkedConditions.some((c) => !c) : !!checkedConditions.some((c) => !!c);
  };

  const productHasPromoStar = (product: Product): boolean => {
    if (!product || !promoCanBeChecked.value || !starConditions.value.promoRules || !productHasSeason(product, starConditions.value.seasons))
      return false;

    const promo: Promo = JSON.parse(starConditions.value.promoRules);
    if (!promo?.conditions || !productHasPromoRules(promo)) return false;

    return !productHasPromoConditions(product, promo.conditions);
  };

  const getProductPromoLabel = (product: Product): PromoLabel => {
    let promoLabel = {};
    if (product && promoCanBeChecked.value && starConditions.value.promoRules && productHasSeason(product, starConditions.value.seasons)) {
      const promo: Promo = JSON.parse(starConditions.value.promoRules);
      if (promo?.conditions && productHasPromoRules(promo) && productHasPromoConditions(product, promo.conditions)) {
        const marketLanguage = localeProperties?.iso?.split('_')?.[0];
        if (marketLanguage) {
          promoLabel = {
            pdpMessage: starConditions.value.pdpMessage?.[marketLanguage] || '',
            pdpExtraLabel: starConditions.value.pdpExtraLabel?.[marketLanguage] || '',
          };
        }
      }
    }
    return promoLabel;
  };

  return {
    productHasPromoStar,
    getProductPromoLabel,
  };
};

export default useProductPromotions;
