/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios from 'axios';

const useAtelier = () => {
  const atelierStock: Ref<string> = ref(null);
  const loading = ref(false);
  const error: Ref<string> = ref(null);

  const getStock = async (good: string, size: string) => {
    loading.value = true;
    atelierStock.value = null;
    error.value = null;
    try {
      const response = await axios.get(`/api/atelier-stock`, {
        params: {
          id: good,
          size,
          time: Date.now(),
        },
        headers: {
          Accept: 'text/plain, json/application',
        },
      });
      atelierStock.value = response.data;
    } catch (err) {
      error.value = err;
    }
    loading.value = false;
  };

  return {
    atelierStock,
    loading,
    error,
    getStock,
  };
};

export default useAtelier;
