import { computed, reactive } from '@nuxtjs/composition-api';

const state: {
  cartItemsBrandData: string[];
  isCartSidebarOpen: boolean;
  isWishlistSidebarOpen: boolean;
  isLoginModalOpen: boolean;
  isSizeSidebarOpen: boolean;
  isNewsletterModalOpen: boolean;
  isCategoryGridView: boolean;
  isWishlistGridView: boolean;
  isFilterSidebarOpen: boolean;
  isMobileMenuOpen: boolean;
  isSearchBarOpen: boolean;
  isCountrySidebarOpen: boolean;
  isSearchModalOpen: boolean;
  isGeoLocatorModalOpen: boolean;
  isPromoPopupCenterOpen: boolean;
  isPromoPopupLeftOpen: boolean;
  isPromoPopupRightOpen: boolean;
} = reactive({
  cartItemsBrandData: [],
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isSizeSidebarOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isSearchBarOpen: false,
  isCountrySidebarOpen: false,
  isSearchModalOpen: false,
  isGeoLocatorModalOpen: false,
  isPromoPopupCenterOpen: false,
  isPromoPopupLeftOpen: false,
  isPromoPopupRightOpen: false,
});

const useUiState = () => {
  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const cartItemsBrand = computed(() => state.cartItemsBrandData);
  const addCartItemBrand = (id: string, brand: string) => {
    state.cartItemsBrandData[id] = brand;
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const isLoginModalOpen = computed(() => state.isLoginModalOpen);
  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const isSearchModalOpen = computed(() => state.isSearchModalOpen);
  const toggleSearchModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSearchModalOpen = !state.isSearchModalOpen;
  };

  const isSizeSidebarOpen = computed(() => state.isSizeSidebarOpen);
  const toggleSizeSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSizeSidebarOpen = !state.isSizeSidebarOpen;
  };

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen);
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);

  const isWishlistGridView = computed(() => state.isWishlistGridView);

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleSearchBar = () => {
    if (state.isSearchBarOpen) toggleMobileMenu();
    state.isSearchBarOpen = !state.isSearchBarOpen;
  };

  const isCountrySidebarOpen = computed(() => state.isCountrySidebarOpen);
  const toggleCountrySidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCountrySidebarOpen = !state.isCountrySidebarOpen;
  };

  const isGeoLocatorModalOpen = computed(() => state.isGeoLocatorModalOpen);
  const toggleGeoLocatorModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isGeoLocatorModalOpen = !state.isGeoLocatorModalOpen;
  };

  const getIsPromoPopupActive = (position) => {
    switch (position) {
      case 'left': {
        return state.isPromoPopupLeftOpen || false;
      }
      case 'right': {
        return state.isPromoPopupRightOpen || false;
      }
      default: {
        return state.isPromoPopupCenterOpen || false;
      }
    }
  };

  const setPromoPopupActive = (position: string, value: boolean) => {
    switch (position) {
      case 'left': {
        state.isPromoPopupLeftOpen = value;
        break;
      }
      case 'right': {
        state.isPromoPopupRightOpen = value;
        break;
      }
      default: {
        state.isPromoPopupCenterOpen = value;
      }
    }
  };

  const togglePromoPopup = (position: string) => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    setPromoPopupActive(position, !getIsPromoPopupActive(position));
  };

  return {
    cartItemsBrand,
    isCartSidebarOpen,
    isWishlistSidebarOpen,
    isLoginModalOpen,
    isSizeSidebarOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isWishlistGridView,
    isFilterSidebarOpen,
    isMobileMenuOpen,
    isCountrySidebarOpen,
    isSearchModalOpen,
    isGeoLocatorModalOpen,
    getIsPromoPopupActive,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleSizeSidebar,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleMobileMenu,
    toggleSearchBar,
    addCartItemBrand,
    toggleCountrySidebar,
    toggleSearchModal,
    toggleGeoLocatorModal,
    togglePromoPopup,
    setPromoPopupActive,
  };
};

export default useUiState;
