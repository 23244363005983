/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { productGetters, Product, categoryGetters, Category } from '@gemini-vsf/composables';

const useUrlHelpers = () => {
  const buildProductUrl = (product: Product): string => {
    const productSlug = productGetters.getSlug(product);
    return `${productSlug}`;
  };

  const buildCategoryUrl = (category: Category): string => {
    const categorySlug = categoryGetters.getSlug(category);
    return `${categorySlug}`;
  };

  return {
    buildProductUrl,
    buildCategoryUrl,
  };
};

export default useUrlHelpers;
